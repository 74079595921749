import "./App.css";
// import axios from "axios";
// import webLogo from "./assests/webLogo.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Failed from './images/failed.svg';

function FailedPayment(props) {
  const location = useLocation();
  // console.log("location",location)
  const navigation = useNavigate();
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/",{ replace: true });
  };
  return (
    <div>
    <div className="main-card">
      <div className="card-outer">
        <div className="" style={{ textAlign: "center" ,}}>
          <div className="">
          <img src={Failed}></img>
          <p className="header3failed">Payment Failed</p>
          <p className="labelMessage1">
            Reference Id : {location.state.response.error.metadata.payment_id}
          </p>
          <p className="labelMessage2">
            Note : Sometimes Activation will take upto 30 mins. kindly be <br />{" "}
            patient we will notify once activated
          </p>
          <a className="btn btn-danger" style={{backgroundColor:"#C31162"}} onClick={onClick}>
            Redirect to Home
          </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default FailedPayment;
