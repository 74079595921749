import "./App.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation ,useNavigate} from "react-router-dom";
import { loadScript } from "./utils";
import SuccessPayment from "./SuccessPayment";
import FailedPayment from "./FailedPayment";
import { useFormik } from "formik";
import * as yup from "yup";
import { rupee } from "./utils";
import { useMemo } from 'react';
import Couponapplied from './images/couponapplied.svg';
import Spinner from 'react-bootstrap/Spinner';


function InitialPayment(props) {
  // console.log(props.userData.state)
  const location = useLocation();
  // props.userData.state.user will have all the info for the user
  const navigate = useNavigate();
  const [payBtnDis,setPayBtnDis]=useState(false)
  const [email,setEmail]=useState(false)
  // const [pre,setPer]=useState(0)
  // const [percent,setPercent]=useState(false)

  const isUser = props.userData.state.user.user_exists,
    mail = props.userData.state.user.user_email;

    // console.log("couponRes",props);
    const pre=props.couponRes.value
   const percent= props.couponRes.in_percent
   const applyCoupon= props.couponRes.applyCoupon
   const coupon=props.couponRes.coupon

 
    // setPer(props.couponRes.value)
    // setPercent(props.couponRes.in_percent)

    // console.log("isUser",isUser)
  const purchaseReqProp = {
    total: 0,
    discount: 0,
    noofcourse: 0,
    data: {
      course_pk: 0,
      course_sub_type: 0,
      course_duration: 0,
      discount: 0,
    },
  };

  const [isVisible, setIsVisible] = useState(0);
  const [paymentResponse, setPaymentResponse] = useState({});
  const [course_info, setCourse_Info] = useState([]);
  const [user, setUser] = useState({
    email: "",
    name: "",
    phone: "",
  });
  const [defaultPrice, setDefaultPrice] = useState(purchaseReqProp);
  const [sumofNeurology, setSumOfNeurology] = useState(purchaseReqProp);
  const [sumofCardiology, setsumofCardiology] = useState(purchaseReqProp);
  const [sumofNephrology,setSumOfNephrology]=useState(purchaseReqProp)
  const [sumofGeneral,setSumOfGeneral]=useState(purchaseReqProp)
  const [deTotalVal,setDetolVal]=useState(0)
  const [userTotal,setUserTotal]=useState(0)

  const [message, setMessage] = useState("");
  const [inpitReadOnly,setReadOnly]=useState(false)
  
  

  const totalPrice = useMemo(() => {
    
    let total = 1999;
    setDetolVal(1999)
    // console.log(total,'tpta;')


    if (applyCoupon==true) {
      // setDetolVal((defaultPrice.total/100)*pre)
      total = !percent?1999-pre    :
      1999-((1999/100)*pre)
      defaultPrice.total=total
      // console.log(total,'tpta;')
    return total;
   }
   else if(isUser==true)
   {
    
    defaultPrice.total=userTotal
   }
   else 
   {
    defaultPrice.total=deTotalVal
    return defaultPrice.total
   }

  }, [applyCoupon]);
  
  // console.log('defaultPrice.total',defaultPrice.total);

  const formik = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        // .max(20, "Name should not exceed 20 Characters")
        .matches(/^[a-zA-Z0-9 !@#$%^&*)(]{2,20}$/, 'User should only enter alphabetic character in the name input field')
        .required("Please enter name"),
        // .required("User should only enter alphabetic character in the name input field"),
      phone: yup
        .string()
        .min(10,"Phone number should not be lesser than 10 digits")
        .max(10,"Phone number should not exceed 10 digits")
        // .max('len', 'Phone number should not exceed 10 Characters', val => val.length <= 10)
        .required("Please enter phone number"),
      email: yup
        .string()
        .email("Invalid email address")
        .required("Please enter email ID"),
    }),

    onSubmit: (values) => {
      setPayBtnDis(true)
  
      let courselist = [];
      if (sumofNeurology.data.course_pk > 0) {
        courselist.push(sumofNeurology.data);
      }

      if (sumofCardiology.data.course_pk > 0) {
        courselist.push(sumofCardiology.data);
      }
      if(sumofNephrology.data.course_pk>0)
      {
        courselist.push(sumofNephrology.data)
      }
      if(sumofGeneral.data.course_pk>0)
      {
        courselist.push(sumofGeneral.data)
      }

      if (defaultPrice.data.course_pk > 0) {
        courselist.push(defaultPrice.data);
      }
      const postData = {
        is_user: isUser,
        name: values.name,
        email: values.email,
        phone: values.phone,
        course_info: courselist,
        coupon:coupon
      };
      //  console.log('postData :',postData);
      
      purchase(postData);
      //  setIsVisible(1)
      //  setIsVisible(-1)
    },
  });

  function emailValidation(){
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(!this.state.email || regex.test(this.state.email) === false){
        this.setState({
            error: "Email is not valid"
        });
        return false;
    }
    return true;
  }

  function reSetAll() {
    //setIsVisible(0)
    //setPaymentResponse({})
    // setCourse_Info([])
    // setUser({})
    setDefaultPrice(purchaseReqProp);
    setSumOfNeurology(purchaseReqProp);
    setsumofCardiology(purchaseReqProp);
    setSumOfGeneral(purchaseReqProp)
  }

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  },[]);

  const handleNeurologyClick = (event) => {
    var checkboxes = document.getElementsByName("Neurology");
    checkboxes.forEach((item) => {
      if (item !== event.target) {
        item.checked = false;
      }

      if (item === event.target) {
        if (item.checked === true) {
          setSumOfNeurology({
            total: Number(item.getAttribute("total")),
            discount: Number(item.getAttribute("discount")),
            noofcourse: 1,
            data: {
              course_pk: Number(item.getAttribute("course_id")),
              course_sub_type: Number(item.getAttribute("variant_type")),
              course_duration: Number(item.getAttribute("course_duration")),
              discount: Number(item.getAttribute("discount")),
            },
          });
        } else {
          setSumOfNeurology(purchaseReqProp);
        }
      }
    });
  };

  const handleCardiologyClick = (event) => {
    var checkboxes = document.getElementsByName("Cardiology");
    // console.log("check",checkboxes)
    checkboxes.forEach((item) => {
      if (item !== event.target) {
        item.checked = false;
      }

      if (item === event.target) {
        if (item.checked === true) {
          //setsumofCardiology(Number(item.getAttribute("total")));
          setsumofCardiology({
            total: Number(item.getAttribute("total")),
            discount: Number(item.getAttribute("discount")),
            noofcourse: 1,
            data: {
              course_pk: Number(item.getAttribute("course_id")),
              course_sub_type: Number(item.getAttribute("variant_type")),
              course_duration: Number(item.getAttribute("course_duration")),
              discount: Number(item.getAttribute("discount")),
            },
          });
        } else {
          setsumofCardiology(purchaseReqProp);
        }
      }
    });
  };
  const handleNephrologyClick = (event) => {
    var checkboxes = document.getElementsByName("Nephrology");
    // console.log("check",checkboxes)
    checkboxes.forEach((item) => {
      if (item !== event.target) {
        item.checked = false;
      }

      if (item === event.target) {
        if (item.checked === true) {
          //setsumofCardiology(Number(item.getAttribute("total")));
          setSumOfNephrology({
            total: Number(item.getAttribute("total")),
            discount: Number(item.getAttribute("discount")),
            noofcourse: 1,
            data: {
              course_pk: Number(item.getAttribute("course_id")),
              course_sub_type: Number(item.getAttribute("variant_type")),
              course_duration: Number(item.getAttribute("course_duration")),
              discount: Number(item.getAttribute("discount")),
            },
          });
        } else {
          setSumOfNephrology(purchaseReqProp);
        }
      }
    });
  };
  const handleGeneralClick = (event) => {
    var checkboxes = document.getElementsByName("General");
    // console.log("check",event)
    checkboxes.forEach((item) => {
      if (item !== event.target) {
        item.checked = false;
      }

      if (item === event.target) {
        if (item.checked === true) {
          //setsumofCardiology(Number(item.getAttribute("total")));
          setSumOfGeneral({
            total: Number(item.getAttribute("total")),
            discount: Number(item.getAttribute("discount")),
            noofcourse: 1,
            data: {
              course_pk: Number(item.getAttribute("course_id")),
              course_sub_type: Number(item.getAttribute("variant_type")),
              course_duration: Number(item.getAttribute("course_duration")),
              discount: Number(item.getAttribute("discount")),
            },
          });
        } else {
          setSumOfGeneral(purchaseReqProp);
        }
      }
    });
  };

  const handleTextChange = (e) => {
    setUser((user) => ({ ...user, [e.target.name]: e.target.value }));
  };
// Fack Api Change
  const getAllCourse = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}campaign/harrison-course-details/${
        // `${process.env.REACT_APP_API_BASE_URL}campaign/temp-course-details/${

          props.userData.state.user.user_exists === true ? 1 : 2
        }${mail ? `?email=${mail}` : ""}`
      )
      .then((res) => {
        let ueseInfo=res.data.user
        // console.log("resp 1=>",ueseInfo)
        
        if(!ueseInfo.name|| !ueseInfo.phone)
        {
          setReadOnly(true)
        }
        // console.log("inpitReadOnly",inpitReadOnly)

        setCourse_Info(res.data.course_info);
        const headerCourseData = res.data.course_info.filter(
          (item) => item.code==53|| item.code ==52
        );
        // console.log(headerCourseData)
        if (headerCourseData && headerCourseData.length > 0) {
          setUserTotal(Number(headerCourseData[0].total))
          setDefaultPrice({
            total:  Number(headerCourseData[0].total),
            discount: Number(headerCourseData[0].discount),
            noofcourse: 1,
            data: {
              course_pk: Number(headerCourseData[0].course_id),
              course_sub_type: Number(headerCourseData[0].variant_type),
              course_duration: Number(headerCourseData[0].course_duration),
              discount: Number(headerCourseData[0].discount),
            },
          });
        }
        // console.log('defaultPrice',defaultPrice)
        // console.log("res.data.user", res.data.user);
        setUser({
          name: res?.data?.user?.name || "",
          email: res?.data?.user?.email || "",
          phone: res?.data?.user?.phone || "",
        });
        setEmail(res?.data?.user?.email?true:false)
        setTimeout(() => {
          // setErr(res.data === "True" ? false : true);
          // setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setTimeout(() => {
          // setLoading(false);
        }, 500);
      });
  };

  const purchase = async (postData) => {
    
    // console.log("api",postData)
    // setErr("");
    // setLoading(true);
  //  console.log(REACT_APP_API_BASE_URL,'UTL');
    const headers = {
      Authorization: "Token e0de7cf5c7aaea8af84fec9ff450bfaa88574c4f",
      "Content-Type": "application/json",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}campaign/harrison-purchase/`,
        postData,
        // { headers }
      )
      .then((res) => {
        setPayBtnDis(false)
        // console.log('success=>',res)
        const options = {
          key: process.env.REACT_APP_RAZOR_PAY_KEY,
          currency: "INR",
          amount: res.data?.total,
          name:"100 Days of Harrison - Daily Discussion enrollment",
          description:"surgtest",
          image:
            "https://surgtest.s3.ap-south-1.amazonaws.com/static/images/logo/paymantLogo.png",
          order_id: res.data?.order_id,
          email: res.data.user_registration.user?.email,
          prefill: {
            contact: res.data.user_registration.user?.phone,
            email: res.data.user_registration.user?.email,
            name: res.data.user_registration.user?.name,
          },
          handler(response) {
            setPaymentResponse(response);
            navigate("/success", {
              state: {
                response
              },
            });
              reSetAll();
           
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          // console.log("payment failed response :",response);
          setPaymentResponse(response);
          // navigate("/success");
          navigate("/failed", {
            state: {
              response
            },
          });
          // setIsVisible(-1);
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });

//failure@razorpay

//const paymentObject = new window.Razorpay(options);
        paymentObject.open();

        // setTimeout(() => {
        //   // setErr(res.data === "True" ? false : true);
        //   // setLoading(false);
        // }, 500);
      })
      .catch((err) => {
        setPayBtnDis(false)
        if(err.response.data?.message)
        {
          setMessage(err.response.data?.message[0])
          alert(err.response.data?.message[0])
        }
        setTimeout(() => {
          //  setLoading(false);
        }, 500);
      });
  };

  useEffect(() => {
    getAllCourse(); // eslint-disable-next-line
  }, []);

  // const submitClick = (e) => {
  //   e.preventDefault();
  //   const form = this.formRef.current;
  //   if (!form.checkValidity()) {
  //       form.reportValidity()
  //       return
  //   }
  //   let courselist = [];
  //   if (sumofNeurology.data.course_pk > 0) {
  //     courselist.push(sumofNeurology.data);
  //   }

  //   if (sumofCardiology.data.course_pk > 0) {
  //     courselist.push(sumofCardiology.data);
  //   }

  //   if (defaultPrice.data.course_pk > 0) {
  //     courselist.push(defaultPrice.data);
  //   }
  //   const postData = {
  //     is_user: isUser,
  //     name: user.name,
  //     email: user.email,
  //     phone: user.phone,
  //     course_info: courselist,
  //   };
  //   purchase(postData);
  // };

  const titleCourseData = course_info
  .filter((item) => item.code ==52 ||item.code==53)
  .map((item,i) => {
    return (
       <div key={i}>
          {/* <p className="header2">{item.name}</p> */}
          <p className="header3" >
           {applyCoupon? <span >
            {!percent?<>
              <div style={{display:'flex',alignItem:'center',flexDirection:'column',
              justifyContent:'center'}}>
            <b>{rupee.format(1999-pre)}</b>
            
            <span className="priceCancels">{rupee.format(1999)}</span>
            </div>
            </>
            :
            <>
            <div style={{display:'flex',alignItem:'center',flexDirection:'column',justifyContent:'center'}}>
            <b>{rupee.format(1999-((1999/100)*pre))}</b>
            <span className="priceCancels">
              {rupee.format(1999)}</span>
              </div>
              </>
            }
            </span>:
            <b>{rupee.format(item.total)}</b>
            }
            </p>
          {
            item.code==52 && !applyCoupon &&
            <span className="priceCancel" style={{marginTop:'2px'}}>{rupee.format(1999)}</span>

          }
        </div>
    )
  })
  const headerCourseData = course_info
    .filter((item) => item.code ==52 ||item.code==53)
    .map((item,i) => {
      return (
        // <>
        //   <p className="header2">{item.name}</p>
        //   <p className="header3">₹ {item.total}</p>
        // </>
        <div className="cardData" key={i}>
          <div className="card-body">
            <input
              type="checkbox"
              // className="mcqCheckBox"
              id={item.id}
              name="video"
              className="check-with-label"
              variant_type={item.variant_type}
              value={item.name}
              discount={item.discount}
              course_id={item.course_id}
              course_duration={item.course_duration}
              total={item.total}
              readOnly={true}
              checked
              // onChange={(event) => handleMCQClick(event)}
              // style={{background: checked? "green":""}}
            />
            <span className="checkboxHeader" htmlFor={item.id}>
              {item.name}
              { applyCoupon?<b>{rupee.format(defaultPrice.total)}</b>
              : <b>{rupee.format(item.total)}</b>
              }
            </span>
            {item.discount>0 && (
              <>
                <br />
                <span className="checkboxFooter" htmlFor={item.id}>
                  You Save {rupee.format(item.discount)}
                </span>
              </>
            )}
          </div>
        </div>
      );
    });

  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      formik.handleSubmit(e)}}>
      {/* <header className="header">
        <h1 className="logo">
          <img
            alt="Surgtest Bailey"
            className="logo_img"
            src="surgtest_logo.svg"
          ></img>
        </h1>
        <ul className="main-nav">
          <li>
          </li>
        </ul>
      </header> */}
      {isVisible === 0 && (
        <div>
          <div className={isUser?'card':'smcard'}>
            {/* <img src="img_avatar.png" alt="Avatar" style="width:100%"> */}
            <div className="container">
              <br/>
              {/* <p className="header1">You Have Purchased</p> */}
              {titleCourseData}
              <p className="subLabel">(incl.of GST)</p>
              {
               applyCoupon &&
                <img style={{marginTop:'5px',marginLeft: '-13px'}} src={Couponapplied} width={180}></img>
              }
              {/* <p style={{height:'20px'}}>Coupon Applied</p> */}
              {/* <hr></hr> */}
              <form autoComplete="off"  style={{marginTop:'16px'}}>
              {/* <form autoComplete="off" onSubmit style={{marginTop:'16px'}}> */}
                {
                  !isUser || inpitReadOnly ?   <div className="row">
                  <div className="col-sm-4" style={{ textAlign: "left" }}>
                    {/* <label className="txtlabel" htmlFor="txtName">
                      Name
                    </label> */}

                    <input
                      id="txtName"
                      className="txtCss"
                      type="name"
                      name="name"
                      placeholder="Enter Your name"
                      // defaultValue={user.name}
                      // value={user.name}
                      
                      onChange={(e) => handleTextChange(e)}
                      // required
                      readOnly={isUser && !inpitReadOnly ? true : false}
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <span style={{ color: "red" }}>{formik.errors.name}</span>
                    ) : null}
                  </div>
                  <div className="col-sm-4" style={{ textAlign: "left" }}>
                    {/* <label className="txtlabel" htmlFor="txtEmail">
                      Email id
                    </label> */}
                    <input
                      id="txtEmail"
                      style={{cursor: 'pointer !important'}}
                      className="txtCss"
                      type="email"
                      name="email"
                      placeholder="Enter Your mail id "
                      // defaultValue={user.email}
                      // value={user.email}
                      // required
                      readOnly={email ? true : false}
                      onChange={(event) => handleTextChange(event)}
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.email}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-sm-4" style={{ textAlign: "left" }}>
                    {/* <label className="txtlabel" htmlFor="txtPhoneNumber">
                      Phone Number
                    </label> */}
                    <input
                      id="txtPhoneNumber"
                      className="txtCss"
                      type="number"
                      name="phone"
                      placeholder="Enter Your phone number "
                      // defaultValue={user.phone}
                      // value={user.phone}
                      // required
                      readOnly={isUser && !inpitReadOnly ? true : false}
                      onChange={(e) => handleTextChange(e)}
                      {...formik.getFieldProps("phone")}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.phone}
                      </span>
                    ) : null}
                  </div>
                </div>:''
                }
             
                <hr></hr>
                {/* <section className="link_block">
                 
    <div className="card w-100 p-4">
      <div className="row ">
        <div className="col-sm-5">
          <img className="d-block w-100" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRIq4tAb4Yk0idsSJ8PMY8kxJ6FtZJ8T9RGg&usqp=CAU" alt=""/>
        </div>
        <div className="col-sm-7  flexCol">
          <div className="card-block linkcss ">
          <p className="labelMessage2 w-auto " style={{marginBottam:'4px', width:'90%'}}>Click on the link to <br/> view syllabus details</p>
            <button style={{width:'124px',height:'44px'}}
            href=" https://www.surgtest.com/surgtest-100-days-of-harrison-daily-discussion/" target="_blank"
            className="btnCss float-right">Open Link</button>
          </div>
        </div>
 
      </div>
    </div>
                </section> */}
                <div className="row" style={{marginTop:'12px'}}>
                  <div className="col-sm-12" style={{fontFamiy:'Montserrat'}}>
                    
                  <p className="labelMessage2" style={{marginBottam:'4px'}}></p>
                  <a 
                  href=" https://www.surgtest.com/surgtest-100-days-of-harrison-daily-discussion/" target="_blank">
                 <sapn className="labelMessage2" style={{color:'var(--bs-link-color)',textDecoration: 'underline'}} > Click </sapn> to view syllabus details
                  </a>
                  
                  </div>
                </div>
                <div className="row" style={{marginTop:'12px'}}>
                  <div className="col-sm-12">
                  {headerCourseData}
                  </div>
                </div>
                  <div className="row">
                    {course_info.length > 0 ? (
                      course_info
                        .filter((item) => item.variant_type==4 &&item.course_id==27)
                        .sort(function(a, b){return a.course_duration - b.course_duration})
                        .map(function (item, i) {
                          return (
                              <div className={isUser?"col-md-4 ":"col-md-6"}>
                                <div className={isUser&&course_info.length==3?"GmCardData":''}>
                            <div className="cardData"   onClick={(event) => handleGeneralClick(event)} key={i}>
                              <div className="card-body">
                                <input
                                  type="checkbox"
                                  tsyle={{cursor: 'pointer !important'}}
                                  className="check-with-label"
                                  id={item.id}
                                  name="General"
                                  variant_type={item.variant_type}
                                  value={item.name}
                                  discount={item.discount}
                                  course_id={item.course_id}
                                  course_duration={item.course_duration}
                                  total={item.total}
                                  onChange={(event) => handleGeneralClick(event)}
                                  // style={{background: checked? "green":""}}
                                />
                                <span className="checkboxHeader" htmlFor={item.id}>
                               <span> {item.name} {rupee.format(item.total)}</span>
                                </span>
                                {item.discount > 0 && (
                                  <>
                                    <br />
                                    <span
                                      className="checkboxFooter"
                                      htmlFor={item.id}
                                    >
                                      You Save {rupee.format(item.discount)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            </div>
                            </div>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr></hr>
                  <div className="row">
                    {course_info.length > 0 ? (
                      course_info
                        .filter((item) => item.variant_type==4 &&item.course_id==19)
                        .sort(function(a, b){return a.course_duration - b.course_duration})
                        .map(function (item, i) {
                          return (
                            <div className={isUser?"col-md-4 ":"col-md-6"} >
                            <div className="cardData" key={i}>
                              <div className="card-body">
                                <input
                                  style={{cursor: 'pointer !important'}}
                                  type="checkbox"
                                  className="check-with-label"
                                  id={item.id}
                                  name="Nephrology"
                                  variant_type={item.variant_type}
                                  value={item.name}
                                  discount={item.discount}
                                  course_id={item.course_id}
                                  course_duration={item.course_duration}
                                  total={item.total}
                                  onChange={(event) => handleNephrologyClick(event)}
                                  // style={{background: checked? "green":""}}
                                />
                                <span className="checkboxHeader" htmlFor={item.id}>
                                 <span> {item.name} {rupee.format(item.total)}</span>
                                </span>
                                {item.discount > 0 && (
                                  <>
                                    <br />
                                    <span
                                      className="checkboxFooter"
                                      htmlFor={item.id}
                                    >
                                      You Save {rupee.format(item.discount)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            </div>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr></hr>
                  <div className="row">
                    {course_info.length > 0 ? (
                      course_info
                        .filter((item) => item.variant_type==4 &&item.course_id==11)
                        .sort(function(a, b){return a.course_duration - b.course_duration})
                        .map(function (item, i) {
                          return (
                            <div className={isUser?"col-md-4 ":"col-md-6"} >
                            <div className="cardData" key={i}>
                              <div className="card-body">
                                <input
                                  type="checkbox"
                                  className="check-with-label"
                                  id={item.id}
                                  name="Neurology"
                                  variant_type={item.variant_type}
                                  value={item.name}
                                  discount={item.discount}
                                  course_id={item.course_id}
                                  course_duration={item.course_duration}
                                  total={item.total}
                                  onChange={(event) => handleNeurologyClick(event)}
                                  // style={{background: checked? "green":""}}
                                />
                                <span className="checkboxHeader" htmlFor={item.id}>
                                  <span>{item.name} {rupee.format(item.total)}</span>
                                </span>
                                {item.discount > 0 && (
                                  <>
                                    <br />
                                    <span
                                      className="checkboxFooter"
                                      htmlFor={item.id}
                                    >
                                      You Save {rupee.format(item.discount)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            </div>
                          );
                        })
                        
                    ) : (
                      <>
                     </>
                    )
                    }
                  </div>
                  <hr></hr>
                  <div className="row">
                    {course_info.length > 0 ? (
                      course_info
                        .filter(
                          (item) => item.variant_type==4 && item.course_id==29)
                          // console.log(item.code)
                          // item.code!=52 ||item.code!=53
                        .sort(function(a, b){return a.course_duration - b.course_duration})
                        .map(function (item, i) {
                          return (
                            <div className={isUser?"col-md-4 ":"col-md-6"} >
                            <div className="cardData" key={i}>
                              <div className="card-body">
                                <input
                                  type="checkbox"
                                  className="check-with-label"
                                  id={item.id}
                                  name="Cardiology"
                                  variant_type={item.variant_type}
                                  value={item.name}
                                  discount={item.discount}
                                  course_id={item.course_id}
                                  course_duration={item.course_duration}
                                  total={item.total}
                                  onChange={(event) => handleCardiologyClick(event)}
                                  // style={{background: checked? "green":""}}
                                />
                                <span className="checkboxHeader" htmlFor={item.id}>
                                  <span>{item.name} {rupee.format(item.total)}</span> 
                                </span>
                                {item.discount > 0 && (
                                  <>
                                    <br />
                                    <span
                                      className="checkboxFooter"
                                      htmlFor={item.id}
                                    >
                                      You Save {rupee.format(item.discount)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            </div>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr></hr>
                  <div className="row">
                    {course_info.length > 0 ? (
                      course_info
                        .filter(
                          (item) => item.variant_type==4 && item.course_id==30)
                          // console.log(item.code)
                          // item.code!=52 ||item.code!=53
                        .sort(function(a, b){return a.course_duration - b.course_duration})
                        .map(function (item, i) {
                          return (
                            <div className={isUser?"col-md-4 ":"col-md-6"} >
                            <div className="cardData" key={i}>
                              <div className="card-body">
                                <input
                                  type="checkbox"
                                  className="check-with-label"
                                  id={item.id}
                                  name="Cardiology"
                                  variant_type={item.variant_type}
                                  value={item.name}
                                  discount={item.discount}
                                  course_id={item.course_id}
                                  course_duration={item.course_duration}
                                  total={item.total}
                                  onChange={(event) => handleCardiologyClick(event)}
                                  // style={{background: checked? "green":""}}
                                />
                                <span className="checkboxHeader" htmlFor={item.id}>
                                  <span>{item.name} {rupee.format(item.total)}</span>
                                </span>
                                {item.discount > 0 && (
                                  <>
                                    <br />
                                    <span
                                      className="checkboxFooter"
                                      htmlFor={item.id}
                                    >
                                      You Save {rupee.format(item.discount)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            </div>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </div>
               
              </form>
            </div>
          </div>
          <div className={isUser?'card_bottom':'smcard_bottom'}>
            {/* <img src="img_avatar.png" alt="Avatar" style="width:100%"> */}
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <span className="txtCourse">
                    <label>
                      {sumofNeurology.noofcourse +sumofNephrology.noofcourse+
                        sumofCardiology.noofcourse +sumofGeneral.noofcourse+
                        defaultPrice.noofcourse}{" "}
                      Courses
                    </label>
                    {/* <label>
                      &nbsp;You Save{" "}<span className="txtSavePrice">
                      {rupee.format(sumofNeurology.discount +
                        sumofCardiology.discount +
                        defaultPrice.discount)}</span>
                    </label> */}
                  </span>
                  <p className="header3">
                   <p style={{fontWeight:500}}> Total{" "}</p>
                    {rupee.format(sumofNeurology.total +sumofNephrology.total+
                      sumofCardiology.total +sumofGeneral.total+
                      defaultPrice.total
                      // totalPrice?totalPrice:defaultPrice.total
                      )}
                  </p>
                </div>
                <div className="col-sm-4"></div>
                <div className="col-sm-4">
                  <button className="btnCss" type="submit"
                   disabled={payBtnDis}>
                   {!payBtnDis?<span>Proceed to Pay</span>:
                   <Spinner animation="border" /> }
                  </button>
                </div>
              </div>
              </div>
          </div>
        </div>
      )}
      {isVisible === 1 && <SuccessPayment props={paymentResponse} />}
      {isVisible === -1 && <FailedPayment props={paymentResponse} />}
    </form>
  );
}

export default InitialPayment;
