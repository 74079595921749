export const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  // format number to US dollar
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// format number to British pounds
export const pounds = Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

// format number to Indian rupee
export const rupee = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,

});

// format number to Euro
export const euro = Intl.NumberFormat('en-DE', {
  style: 'currency',
  currency: 'EUR',
});

// console.log('Dollars: ' + USDollar.format(price));
// Dollars: $143,450.00

// console.log(`Pounds: ${pounds.format(price)}`);
// Pounds: £143,450.00

// console.log('Rupees: ' + rupee.format(price));
// Rupees: ₹1,43,450.00

// console.log(`Euro: ${euro.format(price)}`);
// Euro: €143,450.00