import "./App.css";
// import axios from "axios";
// import webLogo from "./assests/webLogo.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Success from './images/success.svg';

function SuccessPayment(props) {
  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const response = params.get("response");
  // console.log('location',location)
  // console.log("Success:",props)
  // console.log("props.razorpay_payment_id:",props.props.razorpay_payment_id)
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/", { replace: true });
  };
  const [pageNav,setPageNav]=useState(true)

  useEffect(() =>
  {
    // console.log("location.state.response");
    if (!location?.state?.response) {
      navigate("/",{ replace: true });
    }

    setTimeout(function(){
      navigate("/", { replace: true });
   }, 10000);

   return () => {
    navigate("/", { replace: true  });
   }

  },[])


  return (
    <div className="main-card">
      {pageNav&&
      <div className="card-outer">
        <div className="card-bodys" style={{ textAlign: "center" ,position: 'unset !important',float:'unset!important'}}>
          {/* <img src="img_avatar.png" alt="Avatar" style="width:100%"> */}

          <img src={Success}></img>
          <p className="header3">Payment Successful</p>
          <p className="labelMessage1">
            Reference Id : {location?.state?.response?.razorpay_payment_id}
          </p>
           <strong  className="labelMessage2" ><a href="https://chat.whatsapp.com/FucMZEkL0Rb6yfEFJ9q0tN" target="_blank">https://chat.whatsapp.com/J6mYenOdkBsGwpbArNNlYk</a></strong>
          <p className="labelMessage2">please click this link to join the 100 Days of Harrison whatsapp group <br />{" "}
             where className links will be posted:-
          </p>
          <p className="labelMessage2">
            Note : Sometimes Activation will take upto 30 mins. kindly be <br />{" "}
            patient we will notify once activated
          </p>
          <button className="btn btn-success" style={{backgroundColor:"#4CD964",border:"0"}} onClick={onClick}>
            Redirect to Home
          </button>
        </div>
      </div>
      }
    </div>
  );
}

export default SuccessPayment;
